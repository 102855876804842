<template>
  <div>
    <div class="container">
      <div class="handle-box">
        <span>标题名称:</span>
        <el-input v-model="name" placeholder="请输入标题名称" class="handle-input mr10 w100"></el-input>
        <span>渠道:</span>
        <el-select
          v-model="clientId"
          @change="channelChange"
          placeholder="请选择"
          class="handle-select mr10"
        >
          <el-option
            :key="item.id"
            v-for="(item) in channelDatas"
            :label="item.description"
            :value="item.clientId"
          ></el-option>
        </el-select>
        <span>类型:</span>
        <el-select v-model="categoryId" placeholder="请选择" class="handle-select mr10">
          <el-option
            :key="item.id"
            v-for="(item) in categoryArr"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
        <span>更新日期：</span>
        <el-date-picker
          v-model="value2"
          style="width: 240px:margin-right:10px;"
          :clearable="false"
          :default-value="defaultDate"
          @change="changeTimer"
          format="YYYY-MM-DD"
          value-format="YYYY-MM-DD"
          type="daterange"
          align="right"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :shortcuts="shortcuts"
          class="mr10"
        >
          <!-- enable -->
          <!-- unlink-panels -->
        </el-date-picker>
        <span>是否启用:</span>
        <el-select v-model="isEnable" placeholder="请选择" class="handle-select mr10 w80">
          <el-option key label="请选择" value></el-option>

          <el-option key="1" label="启用" value="true"></el-option>
          <el-option key="0" label="禁用" value="false"></el-option>
        </el-select>

        <el-button type="primary ml10" icon="el-icon-search" @click="handleSearch">搜索</el-button>
      </div>
      <div class="btns">
        <el-button type="primary ml10" icon="el-icon-plus" @click="addCate(1)">添加文章</el-button>
        <!-- <el-button type="primary ml10"
                   icon="el-icon-delete"
        @click="changeStatus(-1)">批量删除</el-button>-->
        <el-button type="primary ml10" icon="el-icon-circle-check" @click="changeStatus(1)">批量启用</el-button>
        <el-button type="primary ml10" icon="el-icon-circle-close" @click="changeStatus(0)">批量禁用</el-button>
        <!-- <el-button type="primary ml10"
                   icon="el-icon-download"
        @click="handleSearch">导出</el-button>-->
      </div>
      <el-table
        :data="list"
        border
        class="table"
        ref="multipleTable"
        header-cell-class-name="table-header"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" align="center"></el-table-column>
        <el-table-column prop="id" label="文章Id" width="120" align="center"></el-table-column>
        <el-table-column prop="name" label="标题" align="center"></el-table-column>
        <el-table-column prop="categoryName" width="120" label="类型" align="center"></el-table-column>
        <el-table-column prop="sort" width="120" label="排序" align="center"></el-table-column>
        <el-table-column prop="isEnable" label="状态" align="center">
          <template #default="scope">
            <el-tag type="success" v-if="scope.row.isEnable">启用</el-tag>
            <el-tag type="danger" v-else>禁用</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="parentName" label="渠道" align="center">
          <template #default="scope">{{clientIdFilter(scope.row.clientId)}}</template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template #default="scope">
            <el-button
              type="text"
              icon="el-icon-edit"
              @click="handleEdit(scope.$index, scope.row)"
            >编辑</el-button>
            <el-button
              type="text"
              icon="el-icon-error"
              v-if="scope.row.isEnable"
              class="red"
              @click="DisableRow(scope.$index, scope.row)"
            >禁用</el-button>
            <el-button
              type="text"
              v-else
              icon="el-icon-success"
              @click="DisableRow(scope.$index, scope.row)"
            >启用</el-button>
            <!-- 
            <el-button type="text"
                       icon="el-icon-delete"
                       class="red"
            @click="delRow(scope.$index, scope.row)">删除</el-button>-->
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          background
          layout="total, prev, pager, next"
          :current-page="pageNum"
          :page-size="pageSize"
          :total="totalSize"
          @current-change="handlePageChange"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import {
  clientConfig,
  seoArticleList,
  seoArticleOption,
  seoCategoryList,
  seoArticleBatch
} from "../../api/index";
export default {
  name: "doc",
  data() {
    return {
      content: "1213",
      defaultDate: [],
      value2: "",
      startTime: "",
      endTime: "",
      shortcuts: [
        {
          text: "最近一周",
          value: (() => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            return [start, end];
          })()
        },
        {
          text: "最近一个月",
          value: (() => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            return [start, end];
          })()
        },
        {
          text: "最近三个月",
          value: (() => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            return [start, end];
          })()
        }
      ],
      channelDatas: [], //渠道
      clientId: "",
      categoryId: "",
      pageNum: 1, // 当前分页
      pageSize: 20, //
      totalSize: 0,
      name: "",
      isEnable: "",
      category: "", // 分类
      rowData: null, // 行数据
      form: {},
      idx: -1,
      id: -1,
      tipsTxt: "",
      ids: [],
      createTime: "",
      updateTime: "",
      categoryArr: []
    };
  },
  created() {
    this.channelArray(); // 渠道
    this.getData();
  },
  methods: {
    changeTimer(v) {
      //  格式化时间
      const d1 = new Date(v[0]);
      const d2 = new Date(v[1]);
      const resDate =
        d1.getFullYear() +
        "-" +
        this.p(d1.getMonth() + 1) +
        "-" +
        this.p(d1.getDate());
      const eDate =
        d2.getFullYear() +
        "-" +
        this.p(d2.getMonth() + 1) +
        "-" +
        this.p(d2.getDate());
      this.createTime = resDate;
      this.updateTime = eDate;
      console.log(resDate, eDate);
    },
    p(s) {
      return s < 10 ? "0" + s : s;
    },
    channelChange(e) {
      this.getCData(e);
    },
    getCData(clientId) {
      let params = {
        clientId: clientId, // 行业
        pageIndex: 1,
        pageSize: 100,
        name: "",
        isEnable: true
      };
      seoCategoryList(params).then(res => {
        console.log(res);
        if (res.code === 0) {
          this.categoryArr = res.data.data;

          console.log(" this.categoryArr", this.categoryArr);
          if (this.categoryArr.length < 1) {
            this.categoryId = "";
          } else {
            this.categoryArr.unshift({
              name: "全部",
              id: ""
            });
          }
        } else {
          this.categoryArr = [];
          this.categoryId = "";
          this.$message({
            message: "类型获取失败",
            type: "error"
          });
        }
      });
    },
    clientIdFilter(val) {
      let str = "";
      this.channelDatas.forEach(element => {
        if (element.clientId == val) {
          str = element.description;
        }
      });
      return str + " - " + val;
    },
    channelArray() {
      clientConfig({
        type:'SEO'
      }).then(res => {
        if (res.code == 0) {
          this.channelDatas = res.data;
          this.channelDatas.unshift({
            description: "全部",
            clientId: ""
          });
        }
        console.log("res", res);
      });
    },
    // 获取子分类
    //禁用
    DisableRow(i, row) {
      this.$confirm("将禁用该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          seoArticleOption(row.id).then(res => {
            console.log("res", res);
            let str = row.isEnable ? "禁用" : "启用";
            if (res.code == 0) {
              this.$message({
                type: "success",
                message: str + "成功!"
              });
              this.getData();
            } else {
              this.$message({
                type: "error",
                message: str + "失败!"
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消禁用"
          });
        });
    },

    //删除
    delRow() {
      this.$confirm("此操作将删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.$message({
            type: "success",
            message: "删除成功!"
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除"
          });
        });
    },
    // 获取 数据
    getData() {
      let params = {
        name: this.name, //数字则查id，文字则查名称，或者标题
        clientId: this.clientId, // 行业
        pageIndex: this.pageNum,
        pageSize: this.pageSize,
        isEnable: this.isEnable == "false" ? false : true,
        categoryId: this.categoryId,
        createTime: this.createTime,
        updateTime: this.updateTime
      };

      if (this.isEnable == "") {
        delete params.isEnable;
      }
      seoArticleList(params).then(res => {
        console.log(res);
        if (res.code === 0) {
          this.list = res.data.data;
          this.totalSize = res.data.total;
          this.$forceUpdate();
        }
      });
    },
    //搜索按钮
    handleSearch() {
      this.pageNum = 1;
      this.getData();
    },

    // 多选操作
    handleSelectionChange(val) {
      let idList = val.map(item => {
        return item.id;
      });
      this.ids = idList;
      console.log(
        idList,
        "multipleSelectionmultipleSelectionmultipleSelection"
      );
    },
    checkIds() {
      if (this.ids.length <= 0) {
        this.$message({
          message: "未选中数据",
          type: "error"
        });
        return false;
      }
    },
    // 批量启用禁用删除 ：-1删除（暂不支持），1启用，0禁用
    changeStatus(val) {
      this.checkIds();
      if (this.ids.length <= 0) {
        return false;
      }
      let ids = this.ids;
      let params = {
        isEnable: val ? true : false,
        ids: ids
      };

      if (val == 1) {
        this.tipsTxt = "启用";
      } else if (val == 0) {
        this.tipsTxt = "禁用";
      }
      seoArticleBatch(params).then(res => {
        if (res.code == 0) {
          this.$message({
            message: this.tipsTxt + res.message,
            type: "success"
          });
          this.getData();
        } else {
          this.$message({
            message: res.message,
            type: "error"
          });
        }
      });
    },

    // 编辑操作
    handleEdit(index, row) {
      console.log(index, row);
      let url = window.location.origin + "/docEdit?id=" + row.id;
      window.open(url, "_blank");
    },

    // 分页导航
    handlePageChange(val) {
      this.pageNum = val;
      this.getData();
    },
    addCate(flag) {
      var url = "";
      if (flag == 1) {
        // 新加
        url = window.location.origin + "/docEdit";
      } else {
        // 修改
      }
      window.open(url, "_blank");
    }
  }
};
</script>

<style>
.handle-box {
  margin-bottom: 20px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 300px;
  display: inline-block;
}
.table {
  width: 100%;
  font-size: 14px;
}
.red {
  color: #ff0000;
}
.mr10 {
  margin-right: 10px;
}
.table-td-thumb {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}
.w80 {
  width: 98px;
}
.ml10 {
  margin-left: 10px;
}
.btns {
  margin-bottom: 10px;
}
.check {
  display: flex;
}
</style>
